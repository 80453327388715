import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Box>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12}>
                <Paper sx={{ padding: 4 }}>
                  <Grid container>
                    <Grid item>
                      <Bio />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper sx={{ padding: 4 }}>
                  <Grid container>
                    <Grid item>
                      <p>
                        No blog posts found. Add markdown posts to
                        "content/blog" (or the directory you specified for the
                        "gatsby-source-filesystem" plugin in gatsby-config.js).
                      </p>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <Box>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sx={{ paddingTop: 4 }}>
              <Paper sx={{ padding: 4 }}>
                <Bio />
              </Paper>
            </Grid>
            <ol style={{ listStyle: `none`, paddingLeft: 0 }}>
              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug

                return (
                  <Grid item xs={12} sx={{ paddingTop: 4 }}>
                    <Paper sx={{ padding: 4 }}>
                      <li key={post.fields.slug}>
                        <article
                          className="post-list-item"
                          itemScope
                          itemType="http://schema.org/Article"
                        >
                          <header>
                            <h2>
                              <Link to={post.fields.slug} itemProp="url">
                                <span itemProp="headline">{title}</span>
                              </Link>
                            </h2>
                            <small>{post.frontmatter.date}</small>
                          </header>
                          <section>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.frontmatter.description || post.excerpt,
                              }}
                              itemProp="description"
                            />
                          </section>
                        </article>
                      </li>
                    </Paper>
                  </Grid>
                )
              })}
            </ol>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
